import { createTheme } from "@mui/material/styles"

const theme = createTheme({
  sectionPadding: "padding-top: 1.5em; padding-bottom: 1.5em;",
  navHeight: 104,
  // navHeightUpMd: 140,
  fonts: {
    primary: "Red Hat Display, sans-serif",
    secondary: "TiemposHeadline",
  },
  layout: {
    contentWidth: 1236,
  },
  palette: {
    primary: {
      // contrastText: "rgba(0, 0, 0, 0.87)",
      main: "#FC4A5A",
      light: "#FFE2E0",
      dark: "#FC4A5A",
    },
    secondary: {
      // contrastText: "rgba(0, 0, 0, 0.87)",
      main: "#2F2F42",
      light: "#F9F5F2",
      dark: "#1E1E29",
    },
    tertiary: {
      main: "#322A6A",
      light: "#8D88A7",
    },
    text: {
      primary: "#2F2F42",
      secondary: "#2F2F42",
      content: "#000000",
      tertiary: "#1A2933",
      disabled: "#10355A",
      hint: "#10355A",
      light: "#FFFFFF",
    },
    alternate: {
      main: "rgb(247, 249, 250)",
      dark: "#e8eaf6",
    },
  },
  typography: {
    fontFamily: `"Red Hat Display", "Open Sans", "Arial", sans-serif`,
  },
})

export default theme
