import React, { useEffect } from "react"
import { Global } from "@emotion/react"
import ThemeTopLayout from "gatsby-theme-material-ui-top-layout/src/components/top-layout"

import { useMediaQuery } from "@mui/material"
import { GlobalStyles } from "../../styles/app.styles"
import Tracker from "@openreplay/tracker"

export default function TopLayout({ children, theme }) {
  const isLg = useMediaQuery(theme.breakpoints.up("lg"), {
    defaultMatches: true,
  })
  const isMd = useMediaQuery(theme.breakpoints.up("md"), {
    defaultMatches: true,
  })
  let navHeight = theme.navHeight
  let sectionPadding = theme.sectionPadding

  if (isLg) {
    navHeight = "140"
  }
  if (isMd) {
    sectionPadding = "padding-top: 5em; padding-bottom: 5em;"
  }

  useEffect(() => {
    if (process.env.GATSBY_ACTIVE_ENV === "production") {
      const tracker = new Tracker({
        projectKey: "NVwL6XDGK1D3btx8VYvi",
        ingestPoint: "https://openreplay.aftershock.agency/ingest",
      })
      tracker.start()
    }
  }, [])

  return (
    <ThemeTopLayout theme={{ ...theme, navHeight, sectionPadding }}>
      <Global styles={GlobalStyles} />
      {children}
    </ThemeTopLayout>
  )
}
